import React from "react";
import PropTypes from "prop-types";
import media from "styled-media-query";
import { isFirefox } from "react-device-detect";
import styled from "styled-components";
import { useParams } from "react-router";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";
import Img from "app/components/Img";
import Flex from "app/components/Flex";
import { ScreenGtMd } from "app/components/MediaQueries";
import {
  InstructorName,
  InstructorCreditsAndBio,
  ViewLessCTA,
} from "./InstructorInfo";

const InstructorImg = styled(Img)`
  overflow: hidden;
  object-fit: cover;
  border-radius: 16px;

  ${({ theme }) => theme.mediaQueries.ltlg} {
    width: 100%;
    height: auto;
  }

  ${media.greaterThan("large")`
    max-height: 540px;
  `}
`;

const ScrollableInstructorInfo = styled(Flex)`
  overflow-y: scroll;
  direction: rtl;
  text-align: left;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track-piece {
    background: ${({ theme }) => theme.colors.monochrome[6]};
  }

  ${isFirefox &&
    `
    scrollbar-width: thin;
    scrollbar-color: grey transparent;
  `}
`;

const InstructorCard = ({
  containerHeight,
  isOverviewExpanded,
  setIsOverviewExpanded,
}) => {
  const { slug } = useParams();
  const [getProgramsV2] = useGetProgramsV2({
    variables: { slug },
  });

  const { instructor } = getProgramsV2;
  const { bio, credits, name, content } = instructor || {};
  const { assets } = content || {};
  const { landscapeURL: thumbnailURL } = assets || {};

  return (
    <Flex
      position="relative"
      flexDirection="column"
      color="white"
      mb={{
        _: `-${containerHeight * 0.02}%`,
        sm: `-${containerHeight * 0.012}%`,
        md: `-${containerHeight * 0.02}%`,
        lg: `-${containerHeight * 0.014}%`,
      }}
      width="100%"
    >
      <InstructorImg src={thumbnailURL} mb={4} />
      <ScreenGtMd>
        <Flex
          position="absolute"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <InstructorName name={name} flex="1 1" pl={4} pt={3} />
          <ScrollableInstructorInfo
            width="30%"
            pl={3}
            ml={3}
            mt={5}
            mb="90px"
            flex="1 1"
          >
            <InstructorCreditsAndBio
              bio={bio}
              credits={credits}
              descriptionColor="monochrome.1"
              flexDirection="column"
            />
          </ScrollableInstructorInfo>
        </Flex>
        {isOverviewExpanded && (
          <Flex
            color="black"
            width="100%"
            justifyContent="flex-end"
            style={{ zIndex: 1 }}
          >
            <ViewLessCTA setIsOverviewExpanded={setIsOverviewExpanded} />
          </Flex>
        )}
      </ScreenGtMd>
    </Flex>
  );
};

InstructorCard.propTypes = {
  containerHeight: PropTypes.number.isRequired,
  isOverviewExpanded: PropTypes.bool.isRequired,
  setIsOverviewExpanded: PropTypes.func.isRequired,
};

export default InstructorCard;
