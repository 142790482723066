import React from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import { H1, H3, P1 } from "app/components/Typography";

export const InstructorName = ({ name, ...instructorNameStyles }) => (
  <Flex {...instructorNameStyles} flexDirection="column">
    <H3 mb={2}>Meet Your Instructor</H3>
    <H1>{name}</H1>
  </Flex>
);

InstructorName.defaultProps = {
  name: "",
};

InstructorName.propTypes = {
  name: PropTypes.string,
};

export const InstructorCreditsAndBio = ({
  credits,
  bio,
  descriptionColor,
  ...creditsAndBioStyles
}) => (
  <Flex {...creditsAndBioStyles}>
    <H3 mb={3}>Credits</H3>
    <P1 mb={4} color={descriptionColor}>
      {credits}
    </P1>
    <H3 mb={3}>Experience</H3>
    <P1 color={descriptionColor}>{bio}</P1>
  </Flex>
);

InstructorCreditsAndBio.defaultProps = {
  bio: "",
  credits: "",
};

InstructorCreditsAndBio.propTypes = {
  bio: PropTypes.string,
  credits: PropTypes.string,
  descriptionColor: PropTypes.string.isRequired,
};

export const ViewLessCTA = ({ setIsOverviewExpanded, ...CTAStyles }) => (
  <P1
    cursor="pointer"
    fontWeight="bold"
    textDecoration="underline"
    onClick={() => setIsOverviewExpanded()}
    {...CTAStyles}
  >
    View Less
  </P1>
);

ViewLessCTA.propTypes = {
  setIsOverviewExpanded: PropTypes.func.isRequired,
};
